import { BaseEntity } from './BaseEntity';

export interface Tag extends BaseEntity {
  name: string
  sort: number
  type: TagType
}

export enum TagType {
  Model = 'model',
  Studio = 'studio',
  Genre = 'genre'
}

export type PartialTag = Partial<Tag>;
