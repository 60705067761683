
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';

import { Rules } from 'async-validator';
import useFormErrors from '@/utils/validate';
import { useCreateTag, useUpdateTag, useTag } from '@/composables/api';
import { PartialTag, TagType } from '@/interfaces/Tag';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialTag = {
  name: '',
  type: TagType.Model
};

const rules: Rules = {
  name: [
    {
      required: true
    }
  ],
  type: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const tagId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();
    const { t } = useI18n();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateTag();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateTag();
    const { formErrors, bindFormItemError } = useFormErrors();

    const { data, dataUpdatedAt } = useTag({ tagId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ tagId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-tags'
                });
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success({
                message: 'success!',
                type: 'success'
              });

              formValues.value = DEFAULT_FORM_VALUES;
              router.push({
                name: 'list-tags'
              });
            },
            onError(error: any) {
              ElMessage.error({
                message: error.response?.data.message,
                type: 'error'
              });
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      IntBoolean,
      page,
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      formErrors,
      TagType,
      submitForm,
      t,
      bindFormItemError
    };
  }
});
